// plugins/device.js
export default ({ app }, inject) => {
    // Default value for SSR
    let isMobile = false;

    if (process.client) {
        // Determine initial state on the client side
        isMobile = window.innerWidth <= 768;

        const updateDevice = () => {
            isMobile = window.innerWidth <= 768;
        };

        // Listen to window resize events on the client
        window.addEventListener('resize', updateDevice);

        // Cleanup listener on route change
        app.router.afterEach(() => {
            window.removeEventListener('resize', updateDevice);
        });
    }

    // Inject the state into the Nuxt context
    inject('dynamicDevice', {
        get isMobile() {
            return isMobile; // Access the updated value
        },
    });
};
