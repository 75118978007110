<template>
    <div class="h-dynamic-screen pb-12 lg:pb-0 text-white lg:mb-[-9rem] lg:mt-[-7rem] error-bg">
        <div class="container h-full">
            <div class="flex flex-col justify-center h-full items-center text-center">
                <img class="mb-6" :src="require('~/assets/images/icons/error-icon.svg')" alt="Torn" />
                <h1 v-if="error.statusCode === 404" class="text-black font-Inter-Bold mb-4 text-6xl lg:text-8xl">
                    {{ error.statusCode }}
                </h1>
                <p class="w-full lg:w-6/12 block text-lg font-Inter-Regular text-black">
                    {{ error.message }}
                </p>
                <PButton to="/" label="Tovább a főoldalra" appearance="yellow" class="mt-12" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error',
    layouts: 'Default',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.$scrollToTop();
    },
};
</script>

<style scoped>
.error-bg {
    @apply bg-center bg-cover bg-white;
}
</style>
